<div class="task-bar flex">
  <div class="flex" style="flex: 1; overflow: hidden;">
    <div class="btn-task-bar" *ngFor="let lv of moreVisited" (click)="navigate(lv.url)">
      <img width="35px" height="35px" src="{{lv.icon}}">
      <span class="left col-12">{{lv.title}}</span>
    </div>
  </div>
  <div class="separator-menu" ></div>
  <div class="left flex" style="width: 226px;" *ngIf="configurationTaskBar && configurationTaskBar.lastVisited?.length > 0">
    <div class="btn-task-bar" *ngFor="let lv of configurationTaskBar.lastVisited" (click)="navigate(lv.url)">
      <img width="35px" height="35px" src="{{lv.icon}}">
      <span class="left col-12">{{lv.title}}</span>
    </div>
  </div>
</div>