import { environment } from "src/environments/environment";

export class Utilities {

    static getCookie(name: string): string {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];

            while (c.charAt(0) == ' ')
                c = c.substring(1, c.length);

            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    static initializeConfiguration() {

        let configuration = Utilities.getConfiguration(true);

        if (configuration) {
            // Clears values that are only used during a session.
            // For performance reasons, these values are restored when reloading
            configuration.daysBeforeDueDate = '7'
            configuration.requiredActionsSelectedEmployeesView = 0
            configuration.requiredActionsSelectedEmployees = null;
            configuration.requiredActionsSearchText = null;

            // Save configuracion
            Utilities.setConfiguration(configuration)
        }
        else {
            configuration = {}
            configuration.daysBeforeDueDate = '7'
            configuration.requiredActionsSelectedEmployeesView = 0
            configuration.requiredActionsSelectedEmployees = null;
            configuration.automaticAppointmentCheckBox = true;
            configuration.calendarCheckBox = true;
            configuration.guidelinesCheckBox = true;
            configuration.treatmentsCheckBox = true;
            configuration.tasksCheckBox = true;
            configuration.maintenanceTasksCheckBox = true;
            configuration.leaveRequestsCheckBox = true;
            configuration.quotesCheckBox = true;
            configuration.requiredActionsShowDetails = false;
            configuration.requiredActionsSearchText = null;

            // Save configuracion
            Utilities.setConfiguration(configuration)
        }
    }

    static replaceAll(str: string, find: string, replace: string): string {
        var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }

    static setCookie(name: string, value: string, days?: number) {
        var expires = "";

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    /**
     * Set configuration of app
     * @param configuration {daysBeforeDueDate?: number}
     */
    static setConfiguration(configuration) {
        localStorage.setItem('configuration', JSON.stringify(configuration));
        environment.global.localConfiguration = configuration;
    }

    /**
     * Gets the configuration of the app
     * 
     * @param forceReload false to get the local copy (it's useful for cases where the user works with multiple tabs),
     *  true to force a reload from the local storage.
     * @returns Configuration. {daysBeforeDueDate?: number}
     */
    static getConfiguration(forceReload?: boolean) {

        if (forceReload || environment.global.localConfiguration == undefined || environment.global.localConfiguration == null) {
            // Keeps a local copy, in case the user opens multiple tabs
            environment.global.localConfiguration = JSON.parse(localStorage.getItem('configuration'));
        }

        return environment.global.localConfiguration;
    }

    /**
     * Set configuration of filter
     * @param configuration {daysBeforeDueDate?: number}
     */
    static setConfigurationFilter(name, filter) {
        if (!name)
            return null;

        localStorage.setItem(name, JSON.stringify(filter));
    }

    /**
     * Get configuration of filter
     * @returns Configuration. {daysBeforeDueDate?: number}
     */
    static getConfigurationFilter(name) {
        if (!name)
            return null;

        return JSON.parse(localStorage.getItem(name));
    }


    /**
     * Output numbers with leading zeros
     * @param num 
     * @param places 
     * @returns 
     */
    static zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }


    /**
     * Create a hexadecimal colour based on a string
     * @param str 
     * @returns 
     */
    static stringToRGB(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++)
            hash = str.charCodeAt(i) + ((hash << 5) - hash);

        var c = (hash & 0x00FFFFFF).toString(16).toUpperCase();
        return "00000".substring(0, 6 - c.length) + c;
    }

    /**
     * Check if DNI / NIE is valid
     * @param dni 
     * @returns 
     */
    static validateDNI(dni) {
        var number, finalChar, validationChar;
        var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

        dni = dni.toUpperCase();

        if (expresion_regular_dni.test(dni) === true) {
            number = dni.substr(0, dni.length - 1);
            number = number.replace('X', 0);
            number = number.replace('Y', 1);
            number = number.replace('Z', 2);

            finalChar = dni.substr(dni.length - 1, 1);
            number = number % 23;

            validationChar = 'TRWAGMYFPDXBNJZSQVHLCKET';
            validationChar = validationChar.substring(number, number + 1);

            return (validationChar != finalChar) ? false : true;
        } else {
            return false;
        }
    }

    /**
     * Round number
     * @param number 
     * @param decimals 
     * @returns 
     */
    static round(number, decimals) {
        // return Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)

        let countDecimals = function (value: number) {
            if (Math.floor(value) === value)
                return 0;

            if (value.toString().split(".").length < 2)
                return 0

            return value.toString().split(".")[1].length || 0;
        }

        let op1 = (Math.abs(number) + Number.EPSILON) * Math.pow(10, decimals)
        if (countDecimals(op1) > decimals)
            op1 = Utilities.round(op1, decimals)

        return (number >= 0 ? 1 : -1) * Math.round(op1) / Math.pow(10, decimals)
    }

    /**
     * 
     * @param str 
     * @returns 
     */
    static removeAccents(str): string {
        // TODO: Juan - Encontré esta función... Iba a implementarla y ví que ya existía la que comenté. 
        //              La comenté solo para probar. Sería interesante comprobar el rendimiento y ver cuál es más rápida.
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        // let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        // let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        // str = str.split('');
        // str.forEach((letter, index) => {
        //     let i = accents.indexOf(letter);
        //     if (i != -1) {
        //         str[index] = accentsOut[i];
        //     }
        // })
        // return str.join('');
    }

    static removeAccentsFromArray(strs: string[]) {
        for (let i = 0; i < strs.length; i++) {
            strs[i] = this.removeAccents(strs[i]);
        }
    }

    static saveFile(filename, body) {

        const blob = new Blob([body], { type: body.type });
        const url = window.URL.createObjectURL(blob);

        if (blob) {
            var fileLink = document.createElement('a');
            fileLink.href = url;

            // it forces the name of the downloaded file
            fileLink.download = filename;

            // triggers the click event
            fileLink.click();
        }
    }

    static toHex(num: number): string {
        const map = "0123456789abcdef";
        let hex = num === 0 ? "0" : "";
        while (num !== 0) {
            hex = map[num & 15] + hex;
            num = num >>> 4;
        }
        return hex;
    }

    /**
     * 
     * @param container 
     * @param $target 
     */
    static ScrollIntoViewIfNeeded(container, $target) {
        if (container && $target) {
            if ($target.position() && $target.position().top != -1) {
                if ($target.position().top < jQuery(container).scrollTop()) {
                    //scroll up
                    let scrollTop = $target.position().top - 15
                    if (scrollTop < 0)
                        scrollTop = 0

                    $(container).animate({ scrollTop: scrollTop }, 0);
                } else if ($target.position().top + $target.height() > $(container).scrollTop() + $(container).innerHeight()) {
                    //scroll down
                    $(container).animate({
                        scrollTop: $target.position().top - $(container).innerHeight() + $target.height() + 15
                    }, 0);
                }
            }
        }
    }
}