import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

    /**
     * Constructor of class
     */
    constructor() { }

    /**
     * Add a item
     * @param key 
     * @param item 
     */
    addItem(key: string, item: any) {
        this.data[key] = item;
    }

    /**
     * Get a specific item
     * @param key 
     * @returns 
     */
    getItem(key: string): any {
        return this.data[key] || null
    }

    /**
     * 
     * @returns 
     */
    getData(): any {
        return this.data;
    }

    /**
     * Delete a item
     * @param key 
     */
    removeItem(key: string) {
        if (this.data[key])
            this.data[key] = undefined
    }

    /**
     * Remove all data
     */
    removeData() {
        this.data = {}
    }


    //#region Properties

    private data: any = {};

    //#endregion
}