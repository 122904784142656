import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

/**
 * Provides a common header for the application.
 */
@Component({
  selector: 'task-bar',
  templateUrl: './task-bar.component.html',
  styleUrls: ['./task-bar.component.scss'],
})
export class TaskBar implements OnInit {

  /**
   * Construtor of component
   */
  constructor(
    private el: ElementRef,
    protected router: Router) {

    this.moreVisited = []
    this.showing = false
  }

  /**
   * Initialize component
   */
  ngOnInit() {

  }

  /**
   * Executed after load component
   */
  ngAfterViewInit() {
    const component = this
    let timeout = null

    $(this.el.nativeElement).find(".task-bar").hover(function () {
      if (timeout)
        clearTimeout(timeout)

      timeout = setTimeout(() => {
        if (environment && environment.user && environment.user.id > 0) {
          if (component.showing == false) {
            component.showing = true
            $(this).addClass("task-bar-hover")
  
            component.configurationTaskBar = JSON.parse(localStorage.getItem('configuration-task-bar'));
            component.moreVisited = _.sortBy(component.configurationTaskBar.moreVisited, 'numVisited').reverse();
          }
        }
      }, 750);
    })

    $(this.el.nativeElement).find(".task-bar").mouseleave(function () {
      $(this).removeClass("task-bar-hover")
      component.showing = false

      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
    })
  }


  //#region Methods

  /**
   * Go to url
   * @param url 
   */
  protected navigate(url) {
    this.router.navigateByUrl(url);

    $(this.el.nativeElement).find(".task-bar").removeClass("task-bar-hover")
    this.showing = false
  }


  /**
   * 
   * @returns 
   */
  protected taskBarAvaible() {
    return (environment && environment.user && environment.user.id > 0)
  }

  //#endregion


  //#region Properties

  protected configurationTaskBar: any
  protected moreVisited: any[]

  private showing: boolean

  //#endregion
}