import {
    HttpContext,
    HttpContextToken,
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EnvironmentUtils } from 'src/environments/environment-utils';
import { ResultsError } from '../models/resultsError';
import { Utilities } from '../utilities/utilities';
import { BaseService } from './base.service';
import { AuthenticationService } from './users/authentication/authentication.service';
import { User } from '../models/users/User';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../models/users/authentication/AuthUser';
import { arrayBuffer } from 'stream/consumers';


// #region Background

const BACKGROUND = new HttpContextToken(() => false);

export function backgroundRequest(context: HttpContext) {
    if (context == null || context == undefined)
        context = new HttpContext()

    return context.set(BACKGROUND, true);
}

// #endregion Background


// #region Error

const ERROR = new HttpContextToken(() => true);

export function hideErrorRequest(context: HttpContext) {
    if (context == null || context == undefined)
        context = new HttpContext()

    return context.set(ERROR, false);
}

// #endregion Background


@Injectable({
    providedIn: 'root'
})
export class HttpClientInterceptor implements HttpInterceptor {

    defaultMessage: string;

    loadersShown = new Map<HttpRequest<any>, HTMLIonLoadingElement>();
    loadersHidden = new Map<HttpRequest<any>, boolean>();
    loadersNotShownYet = new Map<HttpRequest<any>, HTMLIonLoadingElement>();

    /**
     * Keeps track if an error message is already being displayed.
     */
    static errorShowing: boolean;

    constructor(
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private alertCtrl: AlertController,
        private loadingController: LoadingController,
        private router: Router) {
        // Sets a default message until a translation is available
        this.defaultMessage = 'Loading';
    }

    /**
     * Clones the request to renew the "authentication" key
     * 
     * @param request  The original request that will be cloned
     * 
     * @returns A cloned request with the most updated authorization header.
     */
    cloneRequestWithNewAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
        let body = request.body;

        // Verifies if this is already an authentication request
        if (request.url.toLowerCase().indexOf('auth-jwt') >= 0) {
            // It ensures that it sends the most recent token (it could have been changed)
            let user: User = environment.user;
            let authUser = new AuthUser();

            if (user != undefined && user != null)
                authUser.token = user.accessToken;

            body = authUser;
        }

        return request.clone({
            headers: request.headers.set('Authorization', this.authenticationService.getAuthorizationHeader()),
            body: body,
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Shows loader
        this.showLoader(request);

        // Manage the request
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse)
                    this.hideLoader(request);

                return event;
            }),
            catchError((error: TypeError) => {
                this.hideLoader(request);

                let header: string;
                let message: string;

                if (error instanceof HttpErrorResponse) {
                    header = this.getServiceTranslation(request, "errorHeader");

                    // Loads the error message from the different fields that the server can report it
                    let errorResults: ResultsError;

                    if (error.error instanceof ArrayBuffer) {
                        // Decodes the object received as an ArrayBuffer
                        const decodedString = new TextDecoder().decode(error.error);
                        errorResults = JSON.parse(decodedString);
                    }
                    else {
                        errorResults = error.error;
                    }

                    message = errorResults.name;

                    if (message == null)
                        message = errorResults.detail;

                    if (message == null) {
                        if (errorResults.non_field_errors != null && errorResults.non_field_errors.length > 0) {
                            message = errorResults.non_field_errors[0];
                        }

                        if (message == null) {
                            message = this.translate.instant('services.defaultErrorMessage'); //error.message;
                        }
                    }

                    if (this.isRequestToRefreshSession(request)) {

                        // It failed to restore the session
                        this.authenticationService.finishedRestoringSession();

                        // Verifies if it failed to restore the session
                        if (error.status == 401) {

                            // Removes any user information from the cookies
                            EnvironmentUtils.saveUser(null);

                            // Go to login page
                            this.router.navigateByUrl('/login');
                        }
                    }
                    else {

                        // Verifies if the session is not valid
                        if (error.status == 401) {
                            // It ensures it's not already on the login page
                            if (this.router.url.toLowerCase().indexOf('/login') >= 0) {
                                // Removes any user information from the cookies
                                EnvironmentUtils.saveUser(null);
                            } else {
                                return this.reconnect(request, next);
                            }
                        }
                    }
                }
                else {
                    header = error.name;
                    message = error.message;
                }

                this.showErrorMessage(request, header, message);

                return throwError(() => error);
            }));
    }

    isRequestToRefreshSession(request: HttpRequest<any>): boolean {
        return request.url.indexOf('auth-jwt-refresh/') >= 0;
    }

    reconnect(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Verifies if it's already in the process of refreshing the ticket
        if (this.authenticationService.isRestoringSession() && !this.isRequestToRefreshSession(request)) {

            // There is another request that was already sent to restore the session. It needs to wait until that request finishes.
            return this.waitForRequest().pipe(
                switchMap(() => {
                    // Updates the authorization header on the request, so it can try again with the new token
                    let clonedRequest = this.cloneRequestWithNewAuthorizationHeader(request);

                    // Causes the current interceptor to process the new request
                    return this.intercept(clonedRequest, next);
                }));
        }

        // Preserves the same behavior than the original request. If the original request was done in background, 
        // then the reconnecion will be also done in background.
        let isBackgroundRequest = this.isBackgroundRequest(request);

        // Sends a request to restore the session using the "Refresh" token
        return this.authenticationService.restoreSession(isBackgroundRequest).pipe(
            switchMap(() => {
                // At this point, the session has been successfully restored. 
                // It updates the authorization header on the original request, so it can try again with the new token
                let clonedRequest = this.cloneRequestWithNewAuthorizationHeader(request);

                // Causes the current interceptor to process the new request
                return this.intercept(clonedRequest, next);
            }));
    }

    private waitForRequest(): Observable<HttpEvent<any>> {

        // Implement the logic for waiting for another request to finish
        return new Observable(observer => {
            this.waitForRequestInternal(observer);
        });
    }

    /**
     * It keeps waiting until a restore session request finishes or fails.
     * 
     * @param observer
     */
    private waitForRequestInternal(observer: Subscriber<HttpEvent<any>>) {
        setTimeout(() => {
            if (this.authenticationService.isRestoringSession()) {
                this.waitForRequestInternal(observer);
            }
            else {
                observer.next();
                observer.complete();
            }
        }, 250); // Waits a quarter of a second
    }

    /**
     * Show error request
     * @param header 
     * @param message 
     * @returns 
     */
    showErrorMessage(request: HttpRequest<any>, header: string, message: string) {
        if (HttpClientInterceptor.errorShowing)
            return;

        if (request && this.hasHiddenRequest(request))
            return

        // Prevents multiple error windows to overlap one over the other
        HttpClientInterceptor.errorShowing = true;

        let alert = this.alertCtrl.create({
            cssClass: 'alert-global',
            header: header,
            message: message,
            buttons: [
                {
                    text: this.translate.instant('app.accept'),
                    role: 'cancel',
                    handler: () => {
                        HttpClientInterceptor.errorShowing = false;
                    }
                }]
        });

        alert.then((res) => {
            res.present();
        });
    }

    /**
     * Shows a dialog that indicates to the user that a request is being processed.
     * 
     * @param request The current HttpRequest.
     */
    showLoader(request: HttpRequest<any>) {
        // Verifies if its a background request
        if (this.isBackgroundRequest(request)) {
            // There is nothing to show
            return;
        }

        // Gets the service defined in the URL
        let messageToShow = this.getServiceTranslation(request, "loading");

        if (messageToShow == undefined || messageToShow == 'services.loading')
            messageToShow = this.defaultMessage;

        // Creates the loader
        this.loadingController.create({
            cssClass: "loading-global",
            message: messageToShow + "...",
            spinner: 'circles',
        }).then((loader) => {
            // Once it's ready, it tries to show it
            this.showLoaderInternal(request, loader);
        });
    }

    /**
     * Hides the loader dialog.
     * 
     * @param request The current HttpRequest.
     */
    hideLoader(request: HttpRequest<any>) {

        // Verifies if its a background request
        if (this.isBackgroundRequest(request)) {
            // There is nothing to hide
            return;
        }

        // Gets the current visible loader
        let loader: HTMLIonLoadingElement = this.loadersShown.get(request);

        // Verifies if the loader was not loaded yet
        if (loader == undefined) {

            // Verifies if this loader is enqueued to be shown later
            loader = this.loadersNotShownYet.get(request)

            if (loader != null) {
                // Removes this loader, so it's not shown
                this.loadersNotShownYet.delete(request);
                return;
            }

            // Marks the loader to indicate that it has been hidden before shown
            this.loadersHidden.set(request, true);
            return;
        }

        this.hideLoaderAndShowNextOne(request, loader);
    }

    private showLoaderInternal(request: HttpRequest<any>, loader: HTMLIonLoadingElement) {

        if (this.loadersShown.size > 0 || HttpClientInterceptor.errorShowing) {

            // A loader is already shown, so it adds this one to the next loader that should be shown when the previous one finishes
            this.loadersNotShownYet.set(request, loader);

            return;
        }

        // Adds the newly created loader to the array
        this.loadersShown.set(request, loader);

        loader.present().then(() => {
            // Verifies if the loader was hidden before it was shown, or if there was an error that should be displayed
            if (this.loadersHidden.get(request) == true || HttpClientInterceptor.errorShowing)
                this.hideLoaderAndShowNextOne(request, loader);
        });
    }

    private hideLoaderAndShowNextOne(request: HttpRequest<any>, loader: HTMLIonLoadingElement) {

        // Removes the entry from all maps
        this.loadersShown.delete(request);
        this.loadersHidden.delete(request);
        this.loadersNotShownYet.delete(request);

        // Hides the loader dialog
        loader.dismiss();

        // Verifies if there is another loader that should be shown
        if (this.loadersShown.size == 0 && this.loadersNotShownYet.size > 0) {

            let iterator = this.loadersNotShownYet.keys();
            let nextRequest = iterator.next().value;

            if (nextRequest != null) {
                let nextLoader: HTMLIonLoadingElement = this.loadersNotShownYet.get(nextRequest);
                this.loadersNotShownYet.delete(nextRequest);

                this.showLoaderInternal(nextRequest, nextLoader);
            }
        }
    }

    /**
     * Indicates if is a background request
     * @param request 
     * @returns 
     */
    private isBackgroundRequest(request: HttpRequest<any>): boolean {
        return request.context != null && request.context.get(BACKGROUND) != null && request.context.get(BACKGROUND) == true;
    }

    /**
     * Indicates if has hidden request
     * @param request 
     * @returns 
     */
    private hasHiddenRequest(request: HttpRequest<any>): boolean {
        return request.context != null && request.context.get(ERROR) != null && request.context.get(ERROR) == false;
    }

    /**
     * Gets a translation for the service that is being invoked.
     * 
     * @param request The current HttpRequest.
     * @param key     The key that should be translated.
     */
    private getServiceTranslation(request: HttpRequest<any>, key: string): string {
        // Gets the service defined in the URL
        let baseService = BaseService.getBaseServiceName(request.url);

        // Constructs the key to locate the I18N string
        baseService = 'services.' + Utilities.replaceAll(baseService, '/', '.');

        if (baseService.charAt(baseService.length - 1) != '.')
            baseService = baseService + '.';

        let baseKeyWithMethod = 'services.';
        let defaultKey = baseService + key;
        let fullKey = defaultKey;

        if (request.method != null) {
            baseKeyWithMethod += request.method.toLowerCase() + '.' + key;
            fullKey = baseService + request.method.toLowerCase() + '.' + key;
        }

        let value = this.translate.instant(fullKey);

        // Sets default values
        if (fullKey === value) {
            if (request.method != null) {

                if (request.method !== "GET") {
                    // Tries to obtain a translation for DELETE, POST and PUT messsages
                    value = this.translate.instant(baseKeyWithMethod);

                    if (value === baseKeyWithMethod)
                        value = defaultKey;
                } else {
                    // Tries to obtain a translation using a key without the method
                    value = this.translate.instant(defaultKey);
                }
            }

            if (defaultKey === value) {
                // Looks for a basic translation
                value = this.translate.instant('services.' + key);

                // Prints the missing keys
                //console.log(defaultKey + " - " + fullKey);
            }
        }

        return value;
    }
}